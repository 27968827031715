<template>
    <v-container fluid>
        <v-form @submit.prevent="saveProfile">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat light>
                    <v-card-text class="pa-0">
                        <v-row class="pt-5">
                            <v-col cols="12" sm="3">
                                <make-avatar v-model="photo" :aspect-ratio="1 / 1" />
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-row>
                                    <v-col class="pt-3" cols="12" sm="6">
                                        <v-text-field v-model="login" type="text" readonly prepend-icon="mdi-card-account-details-outline" :label="$t('login') " color="primary" outlined rounded dense>
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="pt-3" cols="12" sm="6">
                                        <ValidationProvider ref="language" rules="required" v-slot="{ errors, valid }">
                                            <v-select v-model="language" :items="languages" :error="!valid" :error-messages="errors" :disabled="loading" item-value="id" item-text="name" prepend-icon="mdi-translate" color="primary" :label="$t('client_language_of_your_personal_account_and_mailing') " outlined rounded dense></v-select>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="pt-3" cols="12">
                                        <ValidationProvider ref="name" rules="required|min:3|max:255" v-slot="{ errors, valid }">
                                            <v-text-field v-model="name" type="text" :error="!valid" :error-messages="errors" :disabled="loading" prepend-icon="mdi-account" :label="$t('client_name')" color="primary" outlined rounded dense clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="pt-3" cols="12">
                                        <ValidationProvider ref="phone" rules="phone" v-slot="{ errors, valid }">
                                            <v-text-field v-model="phoneRaw" type="tel" v-mask="phoneMask" :error-messages="errors" :disabled="loading" prepend-icon="mdi-phone" :label="$t('phone')" color="primary" outlined rounded dense clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="pt-3" cols="12">
                                        <ValidationProvider ref="email" rules="email" v-slot="{ errors, valid }">
                                            <v-text-field v-model="email" type="email" :error-messages="errors" :disabled="loading" :error="!valid" prepend-icon="mdi-email" :label="$t('email')" outlined rounded dense color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="country" rules="min:1" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="country" :items="countryItems" :error="!valid" :error-messages="errors" :search-input.sync="countrySearching" item-text="name" item-value="id" prepend-icon="mdi-web" :loading="loadingCountries" :disabled="loading" @click="clearCountries" :no-data-text="
                                            countrySearching
                                                ? $t('nothing_found_by', {
                                                      search: countrySearching,
                                                  })
                                                : $t(
                                                      'nothing_found_country_name'
                                                  )
                                        " :label="$t('country')" @click:clear="countryItems = []" outlined rounded dense color="primary" return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="city" rules="min:1" v-slot="{ errors, valid }">
                                    <v-autocomplete v-model="city" :items="cityItems" :error="!valid" :error-messages="errors" :search-input.sync="citySearching" item-text="name" item-value="id" prepend-icon="mdi-city" :loading="loadingCities" :disabled="loading || !country" @click="clearCities" :no-data-text="
                                            citySearching
                                                ? $t('nothing_found_by', {
                                                      search: citySearching,
                                                  })
                                                : $t('nothing_found_city_name')
                                        " :label="$t('city')" @click:clear="cityItems = []" outlined rounded dense color="primary" return-object clearable autocomplete="off">
                                    </v-autocomplete>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12">
                                <ValidationProvider ref="notes" rules="min:3|max:65535" v-slot="{ errors, valid }">
                                    <v-textarea v-model="notes" type="text" :error-messages="errors" :disabled="loading" :label="$t('notes')" rows="5" outlined rounded dense color="primary" auto-grow clearable>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="pt-8">
                        <v-switch v-model="mailing" :disabled="!email || loading" class="mt-0" color="primary" hide-details :label="$t('client_receive_mailings_about_the_location_of_wagons')"></v-switch>
                        <v-spacer></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading" color="primary">
                            {{ $t("save") }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>
import debounce from "lodash/debounce"
import { ValidationObserver, ValidationProvider } from "vee-validate"
import { mask } from "vue-the-mask"
import { mapActions, mapGetters } from "vuex"
import MakeAvatar from "../components/MakeAvatar.vue"

export default {
    name: "ProfileForm",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeAvatar,
    },
    inject: ["forceRerender"],
    data() {
        return {
            heading: null,
            progress: 0,
            create: false,
            phoneRaw: null,
            int_phone: null,
            id: null,
            login: null,
            name: null,
            email: null,
            notes: null,
            language: null,
            languages: [],
            active: false,
            deleted: false,
            password: null,
            passwordHidden: true,
            password_confirmation: null,
            passwordConfirmHidden: true,
            loading: false,
            loadingCities: false,
            loadingCountries: false,
            photo: null,
            mailing: null,
            country: null,
            countryItems: [],
            countrySearching: null,
            city: null,
            cityItems: [],
            citySearching: null,
            //birthday: null,
            //dialogBirthday: false,
        };
    },
    computed: {
        ...mapGetters([
            "lang",
            "phoneMask",
            "phoneIntMask",
            "defaultAvatar",
            "lang",
        ]),
        phone: function () {
            return this.changePhone(this.phoneRaw);
        },
        form_required: function () {
            if (
                this.$route.name === "client.edit" ||
                (this.$route.name === "profile" && this.$auth.check())
            ) {
                return "";
            }
            return "required|";
        },
    },
    watch: {
        countrySearching: debounce(function (val) {
            if (val && !this.country) {
                this.getCountries(val);
            }
        }, 500),
        citySearching: debounce(function (val) {
            if (val && this.country && !this.city) {
                this.getCities(val);
            }
        }, 500),
        password(val) {
            if (val && val !== this.password_confirmation) {
                this.$refs.confirmation_password.setErrors([
                    this.$t("validations.rules.confirmed", {
                        _field_: this.$t(
                            "validations.fields.confirmation_password"
                        ),
                    }),
                ]);
            }
        },
        password_confirmation(val) {
            if (val && val !== this.password) {
                this.$refs.password.setErrors([
                    this.$t("validations.rules.confirmed", {
                        _field_: this.$t("validations.fields.password"),
                    }),
                ]);
            } else {
                this.$refs.password.reset();
            }
        },
        email(val) {
            if (!val) {
                this.mailing = false
            }
        },
        /*
        dialogBirthday(val) {
            val && setTimeout(() => (this.$refs.pickerBirthday.activePicker = 'YEAR'))
        },
        */
    },
    mounted() {
        //this.getSexes()
        this.getLanguages()
        this.getProfile();
    },
    methods: {
        ...mapActions(["setUserName", "setUserPosition", "setUserAvatar"]),
        clearCountries() {
            if (!this.country) {
                this.countryItems = [];
                this.city = null;
                this.cityItems = [];
            }
        },
        clearCities() {
            if (!this.city) {
                this.cityItems = [];
            }
        },
        async getLanguages() {
            var _this = this
            this.progress = 0
            this.loading = true
            await this.$http
                .get("client/language", {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            )
                        }
                    },
                })
                .then((res) => {
                    this.languages = res.body?.data
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_get_list_languages"))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0],
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally((end) => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getCities(str) {
            if (str) {
                this.loadingCities = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.city = str;
                }
                if (this.country) {
                    if (this.country.id) {
                        params.country = this.country.id;
                    } else {
                        params.country = this.country;
                    }
                }
                if (this.lang) {
                    params.language = this.lang;
                }
                await this.$http
                    .get("client/city", {
                        params: params,
                    })
                    .then((res) => {
                        this.cityItems = res.body.data;
                    })
                    .catch((err) => {
                        this.cityItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_cities")
                        );
                    })
                    .finally((end) => {
                        this.loadingCities = false;
                    });
            }
        },
        async getCountries(str) {
            if (str) {
                this.loadingCountries = true;
                let params = {};
                params.filter = "search";
                if (str !== "undefined") {
                    params.country = str;
                }
                if (this.lang) {
                    params.language = this.lang;
                }
                await this.$http
                    .get("client/country", {
                        params: params,
                    })
                    .then((res) => {
                        this.countryItems = res.body.data;
                    })
                    .catch((err) => {
                        this.countryItems = [];
                        this.$toastr.error(
                            this.$t("failed_to_get_list_countries")
                        );
                    })
                    .finally((end) => {
                        this.loadingCountries = false;
                    });
            }
        },
        async getProfile() {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            let params = {};
            if (this.lang) {
                params.language = this.lang;
            }
            await this.$http
                .get(`client/profile`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    this.login = res.body.data.login;
                    this.name = res.body.data.name;
                    this.phoneRaw = res.body.data.phone;
                    this.email = res.body.data.email;
                    this.photo = res.body.data.photo;
                    this.mailing = res.body.data.mailing;
                    this.language = res.body.data.language
                    this.notes = res.body.data.notes;

                    let country = res.body.data.country;
                    if (country && country.id) {
                        this.country = country.id;
                        this.countryItems = [res.body.data.country];
                    } else {
                        this.countryItems = [];
                    }

                    let city = res.body.data.city;
                    if (city && city.id) {
                        this.city = city.id;
                        this.cityItems = [res.body.data.city];
                    } else {
                        this.cityItems = [];
                    }
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("failed_to_get_client"));
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
        async saveProfile() {
            var _this = this;
            this.progress = 0;
            this.loading = true;
            var formData = new FormData();
            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo);
                    var blob = this.dataURL64toBlob(this.photo);
                    if (mimeType && blob) {
                        formData.append("photo", blob, mimeType);
                    }
                } else {
                    formData.append("photo", this.photo);
                }
            }
            if (this.name) {
                formData.append("name", this.name);
            }
            if (this.phone) {
                formData.append("phone", this.phone);
            }
            if (this.email) {
                formData.append("email", this.email);
            }
            if (this.password) {
                formData.append("password", this.password);
            }
            if (this.notes) {
                formData.append("notes", this.notes);
            }
            if (this.language) {
                formData.append("language", this.language)
            }
            if (this.country) {
                if (this.country.id) {
                    formData.append("country", this.country.id);
                } else {
                    formData.append("country", this.country);
                }
            }
            if (this.city) {
                if (this.city.id) {
                    formData.append("city", this.city.id);
                } else {
                    formData.append("city", this.city);
                }
            }
            if (this.mailing) {
                formData.append("mailing", 1);
            }

            // Save
            await this.$http
                .put(`client/profile`, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round(
                                (e.loaded / e.total) * 100
                            );
                        }
                    },
                })
                .then((res) => {
                    this.$toastr.success(this.$t("client_has_been_updated"));
                    //  if ((this.$route.params.id && this.$route.params.id == this.$auth.user().id) || (this.$route.name === 'profile' && this.$auth.check())) {
                    this.$auth.fetch().then((res) => {
                        this.setUserName(this.$auth.user().name);
                        this.setUserPosition(this.$auth.user().position);
                        this.setUserAvatar(
                            this.$auth.user().photo
                                ? this.$auth.user().photo
                                : this.defaultAvatar
                        );
                    });
                    //}
                    //this.forceRerender()
                })
                .catch((err) => {
                    this.$toastr.error(this.$t("client_has_not_been_updated"));
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0],
                                    ]);
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message);
                        }
                    }
                })
                .finally((end) => {
                    this.progress = 0;
                    this.loading = false;
                });
        },
    },
};
</script>
